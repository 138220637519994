var render, staticRenderFns
import script from "./DraggableItem_workMan2.vue?vue&type=script&lang=js"
export * from "./DraggableItem_workMan2.vue?vue&type=script&lang=js"
import style0 from "./DraggableItem_workMan2.vue?vue&type=style&index=0&id=59222264&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59222264",
  null
  
)

export default component.exports