// 表单属性【右面板】
export const formConf = {
  formRef: "elForm",
  formModel: "formData",
  size: "medium",
  labelPosition: "top",
  labelWidth: 100,
  formRules: "rules",
  gutter: 15,
  disabled: false,
  span: 24,
  formBtns: true
}

// 输入型组件 【左面板】
export const inputComponents = [
  {
    __config__: {
      label: "问答题",
      labelWidth: null,
      showLabel: true,
      tag: "el-input",
      tagIcon: "textarea",
      defaultValue: undefined,
      required: true,
      layout: "colFormItem",
      span: 24,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/input"
    },
    type: "textarea",
    placeholder: "请输入",
    autosize: {
      minRows: 4,
      maxRows: 4
    },
    style: {
      width: "100%"
    },
    maxlength: null,
    "show-word-limit": false,
    readonly: false,
    disabled: false
  },
  {
    __config__: {
      label: "编辑器",
      showLabel: true,
      changeTag: true,
      labelWidth: null,
      tag: "tinymce",
      tagIcon: "rich-text",
      defaultValue: null,
      span: 24,
      layout: "colFormItem",
      required: true,
      regList: [],
      document: "http://tinymce.ax-z.cn"
    },
    placeholder: "请输入",
    height: 300, // 编辑器高度
    branding: false // 隐藏右下角品牌烙印
  }
]

// 选择型组件 【左面板】
export const selectComponents = [
  {
    __config__: {
      label: "单选题",
      labelWidth: null,
      showLabel: true,
      tag: "el-radio-group",
      tagIcon: "radio",
      changeTag: true,
      defaultValue: undefined,
      layout: "colFormItem",
      span: 24,
      optionType: "default",
      regList: [],
      required: true,
      border: false,
      document: "https://element.eleme.cn/#/zh-CN/component/radio"
    },
    __slot__: {
      options: [
        {
          label: "选项一",
          value: 1
        },
        {
          label: "选项二",
          value: 2
        }
      ]
    },
    style: {},
    size: "medium",
    disabled: false
  },
  {
    __config__: {
      label: "多选题",
      tag: "el-checkbox-group",
      tagIcon: "checkbox",
      defaultValue: [],
      span: 24,
      showLabel: true,
      labelWidth: null,
      layout: "colFormItem",
      optionType: "default",
      required: true,
      regList: [],
      changeTag: true,
      border: false,
      document: "https://element.eleme.cn/#/zh-CN/component/checkbox"
    },
    __slot__: {
      options: [
        {
          label: "选项一",
          value: 1
        },
        {
          label: "选项二",
          value: 2
        }
      ]
    },
    style: {},
    size: "medium",
    min: null,
    max: null,
    disabled: false
  },
  {
    __config__: {
      label: "评分",
      tag: "el-rate",
      tagIcon: "rate",
      defaultValue: 0,
      span: 24,
      showLabel: true,
      labelWidth: null,
      layout: "colFormItem",
      required: true,
      regList: [],
      changeTag: true,
      document: "https://element.eleme.cn/#/zh-CN/component/rate"
    },
    style: {},
    max: 5,
    "allow-half": false,
    "show-text": false,
    "show-score": false,
    disabled: false
  },
  {
    __config__: {
      label: "上传",
      tag: "el-upload",
      tagIcon: "upload",
      layout: "colFormItem",
      defaultValue: null,
      showLabel: true,
      labelWidth: null,
      required: true,
      span: 24,
      showTip: false,
      buttonText: "点击上传",
      regList: [],
      changeTag: true,
      fileSize: 2,
      sizeUnit: "MB",
      document: "https://element.eleme.cn/#/zh-CN/component/upload"
    },
    __slot__: {
      "list-type": true
    },
    action: "https://jsonplaceholder.typicode.com/posts/",
    disabled: false,
    accept: "",
    name: "file",
    "auto-upload": true,
    "list-type": "text",
    multiple: false
  }
]

// 布局型组件 【左面板】
export const layoutComponents = [
  {
    __config__: {
      layout: "rowFormItem",
      tagIcon: "row",
      label: "行容器",
      layoutTree: true,
      document:
        "https://element.eleme.cn/#/zh-CN/component/layout#row-attributes"
    },
    type: "default",
    justify: "start",
    align: "top"
  },
  {
    __config__: {
      label: "按钮",
      showLabel: true,
      changeTag: true,
      labelWidth: null,
      tag: "el-button",
      tagIcon: "button",
      span: 24,
      layout: "colFormItem",
      document: "https://element.eleme.cn/#/zh-CN/component/button"
    },
    __slot__: {
      default: "主要按钮"
    },
    type: "primary",
    icon: "el-icon-search",
    round: false,
    size: "medium",
    plain: false,
    circle: false,
    disabled: false
  },
  {
    __config__: {
      layout: "colFormItem",
      tagIcon: "table",
      tag: "el-table",
      document: "https://element.eleme.cn/#/zh-CN/component/table",
      span: 24,
      formId: 101,
      renderKey: 1595761764203,
      componentName: "row101",
      showLabel: true,
      changeTag: true,
      labelWidth: null,
      label: "表格[开发中]",
      children: [
        {
          __config__: {
            layout: "raw",
            tag: "el-table-column",
            renderKey: 15957617660153
          },
          prop: "date",
          label: "日期"
        },
        {
          __config__: {
            layout: "raw",
            tag: "el-table-column",
            renderKey: 15957617660152
          },
          prop: "address",
          label: "地址"
        },
        {
          __config__: {
            layout: "raw",
            tag: "el-table-column",
            renderKey: 15957617660151
          },
          prop: "name",
          label: "名称"
        },
        {
          __config__: {
            layout: "raw",
            tag: "el-table-column",
            renderKey: 1595774496335,
            children: [
              {
                __config__: {
                  label: "按钮",
                  tag: "el-button",
                  tagIcon: "button",
                  layout: "raw",
                  renderKey: 1595779809901
                },
                __slot__: {
                  default: "主要按钮"
                },
                type: "primary",
                icon: "el-icon-search",
                round: false,
                size: "medium"
              }
            ]
          },
          label: "操作"
        }
      ]
    },
    data: [
      {
        date: "2016-05-02",
        name: "王小虎",
        address: "上海市普陀区金沙江路 1518 弄"
      }
    ],
    border: true,
    type: "default",
    justify: "start",
    align: "top"
  }
]
