<template>
  <div class="right-board">
    <div class="wrap2">
      <p>评分:</p>
      <div v-if="scoreflag == 'false'" class="score">
        <div class="radio">
          <el-radio-group v-model="scoreArr.correctingFineScore">
            <el-radio :label="4">优秀</el-radio>
            <el-radio :label="3">良好</el-radio>
            <el-radio :label="1">合格</el-radio>
            <el-radio :label="0">不合格</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div v-if="scoreflag == 'true'" class="score">
        <span>本题得分：</span>
        <!-- <el-input placeholder="请输入得分" prefix-icon="el-icon-edit" size="mini" class="myinput" disabled @input="impose(scoreVal)"
          v-model="scoreVal"></el-input>{{scoreVal.toFixed(1)}} -->
        <el-input placeholder="请输入得分" prefix-icon="el-icon-edit" size="mini" class="myinput" :disabled="disabled"
          v-model="scoreVal"></el-input>
      </div>
      <div class="input">
        <el-input placeholder="请输入评语" type="textarea" :rows="5" class="mysinput" v-model="scoreArr.correctingFineComment">
        </el-input>
      </div>
      <p style="margin-bottom:10px" class="ability">能力评星：</p>
      <div>
        <div class="star" v-for="(item, index) in power" :key="index">
          <span class="starname">{{ item.power }}</span>
          <el-rate v-model="item.star" allow-half @change="onChange(item)"></el-rate>
        </div>
      </div>
    </div>
    <div class="btn">
      <div class="cancel">取消</div>
      <div class="confirm" @click="subcheck">确认</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    parameter: Object,
    scoreflag: String, //是否为优良
    arrlength: Number,
    id: Number, //问卷答题情况返回的ID，提交批改需要
    formid: String, //当前题目对应的表单id
    power: Array, //当前题目对应的能力标准
    stuInfo: Object, //当前学员的信息
    workid: Number, //当前问卷的id
    firstScore: Number, //第一题的得分
    themetype: Number, //作业主题类型
    currentquestScore: [Number,String], //当前题目的总得分
    FineComentArr:[Array,Object]
  },

  data() {
    return {
      isEdit: false,
      scoreArr: {
        index: 0,
        correctingFineScore: 0, //批改分数
        correctingFineComment: '', //批改评语
        correctingUserId: '', //批改者ID
        questionnaireId: '', //问卷ID
        userId: '', //答题者ID
        createCorrectingTime: '', //创建批改时间
        updateCorrectingTime: '', //修改批改时间
      },
      scoreVal: 0,
      checkArr: {
        comment: "", //每套问卷的点评
        score: "", //每套问卷的评分
        checkList: [] //每道题的批改详情
      }, //本地存储的批改数组，不包括批改人ID与问卷答题情况返回的ID
      disabled: true
    };
  },
  methods: {
    //定义清空批改方法
    clearAll() {
      this.scoreVal = 0;
    },
    onChange(item) {
      if (this.disabled) {
        let val = Number((item.star / 5 * item.weight * this.currentquestScore));
        let obj = {
          powerId: item.powerId,
          powerScore: val
        }
        this.$emit('computeScore', obj);
        let result = 0;
        let allStar = 0;
        for (var i = 0; i < this.power.length; i++) {
          result += this.power[i].score
          allStar += this.power[i].star
        }
        if (allStar == 74.5) {
          this.scoreVal = Number((result.toFixed(1) - 0.1).toFixed(1))
        } else {
          this.scoreVal = Number(result.toFixed(1))
        }
      }

    },
    //提交批改
    subcheck() {
      if (this.scoreflag == "false") {
        if (this.scoreArr.correctingFineComment == '' || this.scoreArr.correctingFineComment == null || this.scoreArr
          .correctingFineComment == undefined) {
          this.$message.error('请输入评语');
          return
        }
        this.scoreArr.correctingUserId = this.$store.state.user.userInfo.id;
        this.scoreArr.questionnaireId = this.id;
        if (!this.isEdit) {
          this.scoreArr.index = this.arrlength;
        }
        this.scoreArr.userId = this.stuInfo.userId;
        this.scoreArr.createCorrectingTime = new Date(this.stuInfo.createTime).getTime();
        this.scoreArr.updateCorrectingTime = new Date();
        let params = {
          correctingContentFine: JSON.stringify(this.scoreArr),
          // correctingUserId: JSON.parse(sessionStorage.getItem("userinfo")).id,
          correctingUserId: this.$store.state.user.userInfo.id,
          id: this.id,
          isEdit: this.isEdit,
        };
        this.$Api.Form.editFine(params)
          .then(res => {
            this.$message({
              type: "success",
              message: "提交成功"
            });
            this.$emit('updateParameter', this.scoreArr.userId)
            this.scoreArr.correctingUserId = '';
            this.isEdit = false;
            this.scoreArr.questionnaireId = '';
            this.scoreArr.userId = '';
            this.scoreArr.index = 0;
            this.scoreArr.createCorrectingTime = '';
            this.scoreArr.updateCorrectingTime = ''
            this.scoreArr.correctingFineScore = 0 //批改分数
            this.scoreArr.correctingFineComment = '' //批改评语

          })
          .catch(err => {
            console.warn(err);
          });
      } else {

        //获取本题的评分与能力评星
        let obj = {
          score: this.scoreVal,
          formid: this.formid,
          power: this.power
        };
        // 1.获取批改内容 2.通过formid判断本题是否已经被批改过 -- 如果没批改过 则添加到批改数组,如果已被批改过 则替换批改内容
        let has = this.checkArr.checkList.filter(item => {
          return item.formid == obj.formid;
        });
        if (has.length == 0) {
          this.checkArr.checkList.push(obj);
          this.check();
        } else {
          let index = this.checkArr.checkList.findIndex(item => {
            return item.formid == obj.formid;
          });
          this.checkArr.checkList.splice(index, 1, obj);
          this.check();
        }
      }
    },
    //提交批改函数
    check() {
      if (this.themetype == 4) {
        if (this.scoreVal > 40) {
          this.$alert("命题说话得分不能超过40分！", "提示");
        } else {
          let params = {
            correctingContent: JSON.stringify(this.checkArr),
            correctingUserId: this.$store.state.user.userInfo.id,
            id: this.id
          };
          this.$emit('updateCheckInfo')
          this.$Api.Form.subCheck(params)
            .then(res => {
              this.$message({
                type: "success",
                message: "提交成功"
              });
              this.$emit('updateCheckInfo')
            })
            .catch(err => {
              console.warn(err);
            });
        }
      } else {
        let params = {
          correctingContent: JSON.stringify(this.checkArr),
          correctingUserId: this.$store.state.user.userInfo.id,
          id: this.id,
        };
        this.$Api.Form.subCheck(params)
          .then(res => {
            this.$message({
              type: "success",
              message: "提交成功"
            });
            this.$emit('updateCheckInfo')
            this.$set(this.checkArr, 'checkList', [])
          })
          .catch(err => {
            console.warn(err);
          });

        this.scoreArr.correctingUserId = this.$store.state.user.userInfo.id;
        this.scoreArr.questionnaireId = this.id;
        if (!this.isEdit) {
          this.scoreArr.index = this.arrlength;
        }
        this.scoreArr.userId = this.stuInfo.userId;
        this.scoreArr.createCorrectingTime = new Date(this.stuInfo.createTime).getTime();
        this.scoreArr.updateCorrectingTime = new Date();
        let params2 = {
          correctingContentFine: JSON.stringify(this.scoreArr),
          correctingUserId: this.$store.state.user.userInfo.id,
          id: this.id,
          isEdit: this.isEdit,
        };
        this.$Api.Form.editFine(params2)
          .then(res => {
            this.$emit('updateParameter', this.scoreArr.userId)
          })
          .catch(err => {
            console.warn(err);
          });
      }
    },
    inputimpose(scoreVal) {
      if (isNaN(scoreVal)) {
        this.$message.error('请输入评语');
      }
    },
    //输入框限制
    impose(scoreVal) {
      if (isNaN(scoreVal)) {
        this.$message.error('请输入正确的分值');
        this.scoreVal = 0
      } else {
        //清除“数字”和“.”以外的字符
        this.scoreVal = scoreVal.replace(/[^\d.]/g, "");
        //只保留第一个. 清除多余的
        this.scoreVal = scoreVal.replace(/\.{2,}/g, ".");
        this.scoreVal = scoreVal.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        //只能输入一个小数
        this.scoreVal = scoreVal.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3');
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02
        if (scoreVal.indexOf(".") < 0 && scoreVal != "") {
          this.scoreVal = parseFloat(scoreVal);
        }
        if (scoreVal < 0 || scoreVal > 100) {
          this.$message.error('分数不能小于0或者大于100');
          this.scoreVal = 0
        }
      }
    },

  },
  watch: {
    //监听批改情况变化
    id(newVal) {
      this.id = newVal
    },
    //监听formid变化
    formid(newVal) {
      this.formid = newVal
    },
    power: {
      handler(newVal) {
        this.power = newVal;
        if (!newVal) {
          this.disabled = false;
        } else if (newVal.length == 0) {
          this.disabled = false;
        } else {
          for (var i = 0; i < newVal.length; i++) {
            if (newVal[i].weight == -1) {
              this.disabled = false;
              break
            } else {
              this.disabled = true
            }
          }
        }
      }
    },
    //监听学员信息情况变化
    stuInfo(newVal) {
      this.stuInfo = newVal
    },
    //监听第一题得分变化
    firstScore(newVal) {
      this.firstScore = newVal
    },
    //监听是否为优良
    scoreflag(newVal) {
      this.scoreflag = newVal
    },
    parameter(newVal) {
      this.parameter = newVal
      this.scoreArr.correctingFineScore = newVal.correctingFineScore //批改分数
      this.scoreArr.correctingFineComment = newVal.correctingFineComment //批改评语
      this.scoreArr.index = newVal.arrindex;
      this.isEdit = true;
    },
    FineComentArr(newVal){
      console.log('FineComentArr:',newVal);
      if(newVal){
        this.$set(this.scoreArr,'correctingFineComment',newVal.correctingFineComment)
      }else{
        this.$set(this.scoreArr,'correctingFineComment','')
      }
    }
  },
  mounted() { },

};
</script>

<style lang="scss" scoped>
.right-board {
  width: 280px;
  // height: 97.7vh;
  // border: 1px solid #cccccc;
  padding-top: 3px;
  padding-bottom: 17px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  overflow-y: scroll;

  .wrap2 {
    padding-left: 20px;
    margin-top: 14px;
    box-sizing: border-box;

    p {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .score {
      // 11111
      // display: flex;
      // align-items: center;
      margin-bottom: 10px;

      .myinput {
        width: 50%;
      }

      .mysinput {
        width: 100%;
      }

      .radio {
        padding: 10px 0;

        .el-radio {
          margin-right: 5px;
        }
      }

      .input {}
    }

    .ability {
      font-weight: normal;
    }

    .star {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;

      .starname {
        width: 50%;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    div {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      text-align: center;
      line-height: 30px;
      font-size: 11px;
    }

    .cancel {
      // box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
      border: 1px solid #508EF9;
      color: #508EF9;
      margin-left: 12px;
    }

    .confirm {
      background-color: #508EF9;
      // box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
      color: #ffffff;
      margin-right: 12px;
    }

    // display: flex;
    // margin-top: 30px;
    // justify-content: space-around;
    // .clear {
    //   margin-right: 30px;
    // }
  }
}

.right-board::-webkit-scrollbar {
  display: none;
}
</style>